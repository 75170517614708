<!-- 推荐管理 -->
<template>
  <div>
    <div class="main" style="display: flex;">
      <div style="width: 100%;">
        <div style="font-size: 26px; font-weight: bold;display:flex">
          <div style="cursor: pointer;padding: 10px 0;" @click="goback">&lt;推荐管理</div>
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex;border-radius: 8px 8px 0 0;background: #C6E7E2;line-height: 34px;font-size: 14px;">
            <div v-for="(item, index) in statusList" :key="index" style="width: 112px;text-align: center;"
              :class="index == currentIndex? 'currentTitle' : 'titlelayout'" @click="changeTitle(index)">{{ item }}</div>
          </div>
          <!-- <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
            @click="addcontent2">+添加课程包</el-button> -->
          <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
            @click="addPush">+添加推荐</el-button>
          <!-- <el-button type="primary" style="height: 34px; line-height: 10px;background: #00A78E;" class="cinput"
            @click="dialogVisibleContent = true">内容管理</el-button> -->
        </div>
        <div
          style="height: 60px;background: #fff;display: flex;justify-content: space-between;padding: 0 20px;align-items: center;">
          <el-input placeholder="请输入推荐名称" prefix-icon="el-icon-search" v-model="keyWords" style="width: 232px;">
          </el-input>
          <div style="display: flex;align-items: center;">
            <div style="color: #3D3D3D;font-size: 14px;margin-left: 10px;">推荐类型筛选:</div>
            <el-select v-model="brandId" placeholder="品牌" clearable class="cinput ac" @change='getList'>
              <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData"
          style="width: 100%; min-height: 600px;padding: 0 20px;" @selection-change="handleSelectionChange">
          <el-table-column prop="content" align="center" label="当前推荐"></el-table-column>
          <el-table-column prop="name" align="center" label="图片">
            <template slot-scope="scope">
              <img :src="scope.row.picUri"  style="width:104px;height:104px"/>
            </template>
          </el-table-column>
          <el-table-column prop="name" align="center" label="推荐类型">
            <template slot-scope="scope">
              <div>{{scope.row.jsonStr.type==1?'富文本':scope.row.jsonStr.type==2?'课程推荐':'积分兑换'}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="weight" align="center" label="权重"></el-table-column>
          <el-table-column prop="teacherName" align="center" label="操作">
            <template slot-scope="scope">
              <div style="display: flex; justify-content: space-around">

                <el-link type="primary" :underline="false" @click="details(scope.row)" v-if="currentIndex==0">编辑
                </el-link>
                <el-link type="primary" :underline="false" @click="deletePush(scope.row)">删除
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding:20px;
          background: #fff;
        ">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageIndex" :page-sizes="[10, 15, 20]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </div>
    </div>

    <el-dialog title="添加推荐" :visible.sync="dialogVisible" width="60%" class="dialog1">
      <div style="display: flex;justify-content: space-between;height: 100%;">
        <div style="width: 50%;border-right: 1px solid #E5E5E5;">
          <div style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;">
            <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
            <div>基础设置</div>
          </div>
          <div>
            <div style="display: flex;align-items: center;">
              <div style="width: 120px;text-align: right;">推荐标题：</div>
              <el-input v-model="content" placeholder="请输入推荐标题" style="width: 264px;"></el-input>
            </div>
            <div style="display: flex;align-items: center;">
              <div style="width: 120px;text-align: right;">权重：</div>
              <el-input v-model="weight" placeholder="请输入权重" style="width: 264px;"></el-input>
            </div>
            <div style="display: flex;align-items: center;">
              <div style="width: 120px;text-align: right;">推荐类型：</div>
              <div style="margin: 20px 0;">
                <el-radio-group v-model="radio" text-color="#00A78E">
                  <el-radio :label="1">富文本</el-radio>
                  <el-radio :label="2">课程</el-radio>
                  <el-radio :label="3">积分兑换</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div style="display: flex;align-items: center;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">品牌分类：</div>
              <el-select v-model="brandId" placeholder="品牌" clearable class="cinput ac" @change='changeBrand
              ' style="width: 160px;">
                <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">推荐时间：</div>
              <el-date-picker v-model="datelist" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;" v-if="radio == 2">
              <div style="width: 120px;text-align: right;">课程关联：</div>
              <el-select v-model="categoryId" placeholder="课程分类" clearable class="cinput ac" @change='getcourseList'
                style="width: 160px;">
                <el-option v-for="item in cateGoryList" :key="item.record.id" :label="item.record.name"
                  :value="item.record.id">
                </el-option>
              </el-select>
              <el-select v-model="coursePageId" placeholder="课程包" clearable class="cinput ac" style="width: 160px;">
                <el-option v-for="item in coursePageList" :key="item.record.id" :label="item.record.name"
                  :value="item.record.id">
                </el-option>
              </el-select>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;" v-if="radio == 3">
              <div style="width: 120px;text-align: right;">关联商品：</div>
              <el-select v-model="goodsId" placeholder="品牌" clearable class="cinput ac" style="width: 160px;">
                <el-option v-for="item in goodsLists" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">推荐封面：</div>
              <input type="file" @change="uploadimgpicUri" id='fileimg' accept='image/*' ref='fileimg'
                style="color:#fff" v-if='!picUri' />
              <img :src="picUri" alt="" style="width: 104px;height: 104px;" v-if="picUri">
              <el-link type="primary" :underline="false" v-if='picUri' @click="picUri = ''">删除</el-link>
            </div>
            <div style="display: flex;align-items: center;margin: 16px 0;">
              <div style="width: 120px;text-align: right;">是否启用：</div>
              <el-switch v-model="isValidity" active-color="#13ce66">
              </el-switch>
            </div>
          </div>
        </div>
        <div style="width: 50%;">
          <div style="display: flex;align-items: center;font-size: 14px;" v-if="radio == 1">
            <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
            <div>内容设置</div>
          </div>
          <quill-editor style="height:80%;width:100%;" v-model="msg" ref="richAnalysis" :options="options2"
            @change="quillchange" v-if="radio == 1"></quill-editor>
          <input type="file" @change="uploadRichImgMethod" accept='image/*' ref='uploadRichImg' id="uploadRichImg"
            style="display:none" />
          <div style="position: absolute;bottom: 0;display: flex;width: 48%;justify-content: flex-end;">
            <el-button @click="dialogVisible=false">取消</el-button>
            <el-button style="background: #00A78E;color: #fff;" @click="saveContent">保存</el-button>
          </div>
        </div>

      </div>
    </el-dialog>

    <!-- <el-dialog title="添加老师" :visible.sync="dialogVisible2" width="30%" >
      <div>
        <div
          style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin-bottom: 10px;">
          <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
          <div>基础设置</div>
        </div>
        <div style="display: flex;align-items: center;">
          <div style="width: 120px;text-align: right;">讲师名称：</div>
          <el-input v-model="input" placeholder="请输入讲师名称" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 20px 0;">
          <div style="width: 120px;text-align: right;">讲师手机：</div>
          <el-input v-model="input" placeholder="请输入讲师手机号码" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;">
          <div style="width: 120px;text-align: right;">简介：</div>
          <el-input type="textarea" :rows="5" placeholder="请输入简介" v-model="textarea" style="width: 264px;"
            maxlength="108" show-word-limit>
          </el-input>
        </div>
        <div
          style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin:20px 0 10px 0;">
          <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
          <div>其他设置</div>
        </div>
        <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 120px;text-align: right;">性别：</div>
          <div>
            <el-radio-group v-model="radio" text-color="#00A78E">
              <el-radio :label="0">女</el-radio>
              <el-radio :label="1">男</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 120px;text-align: right;">生日：</div>
          <div>
            <el-date-picker v-model="value1" type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 120px;text-align: right;">讲师照片：</div>
          <input type="file" @change="uploadimg2" id='file2' accept='image/*' ref='fileimg' style="color:#fff" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" style="background: #00A78E;color: #fff;">确 定</el-button>
      </span>
    </el-dialog> -->

    <el-dialog title="内容管理" :visible.sync="dialogVisibleContent" width="60%" class="dialog1">
      <div style="color: #ACB6B5">当前位置：嘉顿肯妮-专业课程</div>
      <div style="display: flex;height: 90%;margin: 20px 0;">
        <div
          style="padding: 8px 16px;box-shadow: 0px 4px 10px 0px rgba(62, 68, 77, 0.2);height: 100%;width: 144px;border-radius: 10px;">
          <div style="color: #ACB6B5;">课程包目录</div>
          <div style="margin: 16px 0;position: relative;">
            <div
              style="position: absolute;width: 5px;height: 24px;border-radius: 0px 27px 30px 0px;background: #00A78E;left: -16px;top: -2px;">
            </div>
            <div class="selectContent">美容手法</div>
          </div>
        </div>
        <div style="width: 80%;margin-left: 20px;">
          <div style="display: flex;justify-content: flex-end;">
            <el-button style="color: #fff;background: #00A78E;" @click="dialogVisibleContentAdd = true">+添加内容
            </el-button>
          </div>
          <el-table :data="tableData2"
            style="width: 95%;display: flex;flex-direction: column;align-items: flex-end;margin-top: 20px;"
            :header-cell-style="{ background: '#F7F7F7' }">
            <el-table-column prop="date" label="课程包名称">
            </el-table-column>
            <el-table-column prop="name" label="权重">
            </el-table-column>
            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <div>
                  <el-link type="primary" :underline="false">编辑</el-link>
                  <el-link type="primary" :underline="false" style="margin-left: 20px;">删除</el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="添加推荐" :visible.sync="dialogVisibleContentAdd" width="30%">
      <div style="overflow: auto;">
        <!-- <div
          style="display: flex;align-items: center;font-size: 14px;width: 100px;justify-content: flex-end;margin-bottom: 10px;">
          <div style="width: 4px;height: 13px;border-radius: 50px;background: #00A78E;margin-right: 4px;"></div>
          <div>基础设置</div>
        </div> -->
        <div style="display: flex;align-items: center;">
          <div style="width: 80px;text-align: right;">推荐标题：</div>
          <el-input v-model="input" placeholder="请输入推荐标题" style="width: 264px;"></el-input>
        </div>
        <div style="display: flex;align-items: center;margin: 20px 0;">
          <div style="width: 80px;text-align: right;">课程权重：</div>
          <el-input v-model="input" placeholder="请输入数字" style="width: 264px;"></el-input>
        </div>
        <!-- <div style="display: flex;align-items: center;margin: 16px 0;">
          <div style="width: 80px;text-align: right;">视频上传：</div>
          <input type="file" id='file2' accept='video/mp4' ref='fileimg' style="color:#fff" @change="uploadvideo" />
          <video :src="vidersrc" v-if="vidersrc" class="avatar" controls="controls" style="width: 351px; height: 198px"
            id="videosrc">
            您的浏览器不支持视频播放
          </video>
        </div> -->
        <!-- <div style="display: flex;margin: 20px 0;flex-direction: column;">
          <div style="width: 80px;text-align: right;">课件设置：</div>
          <quill-editor style="height:200px;width:100%;" v-model="msg" ref="richAnalysis" :options="options2" 
            @change="quillchange"></quill-editor>
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" style="background: #00A78E;color: #fff;">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
var COS = require('cos-js-sdk-v5');
var cos = new COS({
  getAuthorization: function (options, callback) {
    getCosCredit().then(result => {
      var data = result.data.data;
      var credentials = data && data.credentials;
      if (!data || !credentials) return console.error('credentials invalid');
      sessionStorage.setItem('bucket', data.bucket)
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    })
  }
});
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],
  ['link', 'image', 'video'],
  ['clean']                                         // remove formatting button
]
import uploadImg from '../../utils/uploadImg'
import uploadRIchImg from '../../utils/uploadRIchImg'
import uploadRichText from '../../utils/uploadRichText'
import { pushAdd,getRichText,pushEdit ,courseList, pushList, pushDelet, goodsList, contentpageList, contentsaveOrUpdate, courseInfoList, levelList, getCosCredit, coursedelete, currentCourseList, courseDeleteAll, courseClassList } from '../../apis/index'
export default {
  data() {
    return {
      currentIndex:0,//顶部标题下标
      tableDataActive:[],//当前推荐
      tableDataDie:[],//失效推荐
      keyWords:'',//搜索关键字
      contentId:'',//内容id，编辑用
      msg: '',
      type: 1,//推荐类型
      cateGoryList: [],//分类列表
      categoryId: '',//分类id
      coursePageList: [],//课程包列表
      coursePageId: '',//课程包id
      goodsLists: [],//商品列表
      goodsId: '',//商品id
      datelist: [],//推荐时间
      picUri: '',//推荐封面
      content: '',//推荐标题
      weight:'',//权重
      isValidity: false,
      tableData2: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisibleContent: false,//内容管理
      dialogVisibleContentAdd: false,//添加内容
      statusList: ['当前推荐', '失效推荐'],
      brandId: 1,
      brandList: [],
      levelLists: [],
      leveId: 1,
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled: true,
      currentPage: 1,
      radio: 1,
      input: "",
      tableData: [

      ],
      options2: {
        theme: "snow",
        placeholder: "请输入内容",
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': function (value) {
                if (value) {
                  document.getElementById('uploadRichImg').click()
                } else {
                  this.quill.format('image', false);
                }
              }
            }
          }
        }
        // modules: {
        //   toolbar: [
        //     ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
        //     //['blockquote', 'code-block'],                      //引用，代码块
        //     [{ header: 1 }, { header: 2 }], // 几级标题
        //     [{ list: "ordered" }, { list: "bullet" }], // 有序列表，无序列表
        //     [{ script: "sub" }, { script: "super" }], // 下角标，上角标
        //     [{ indent: "-1" }, { indent: "+1" }], // 缩进
        //     //[{ direction: "rtl" }], // 文字输入方向
        //     [{ size: ["small", false, "large", "huge"] }], // 字体大小
        //     [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
        //     [{ color: [] }, { background: [] }], // 颜色选择
        //     [{ font: [] }], // 字体
        //     [{ align: [] }], // 居中
        //     ["clean"],　　　　//清除样式
        //     [("link", "image","video")],//控制能上传的内容
        //   ],
        // },
      },
    };
  },
  watch: {
    radio(){
      this.changeBrand()
    },
    keyWords(newdata,olddata){
      console.log(newdata)
      if(newdata==''){
        this.getList()
      }else{
        let tableData=this.tableData
       let tableDataNew=tableData.filter(item=>{
          return item.content.indexOf(newdata)!=-1
        })
        this.tableData=tableDataNew
      }
    },
    input(newdata, olddata) {
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.input = ''
        this.list()
        this.isshow = false;
      }
    },
    categoryId(newdata, olddata) {
      this.coursePageId = ''
    },
    brandId(newdata, olddata) {
      this.coursePageId = this.categoryId = this.coursePageList = ''
    }
  },
  created() {
    this.brandList = this.$store.state.brandList
    // let id = this.$route.query.id
    // this.id = id
    this.getList()
    this.getCateGorylist()
  },
  //     this.leave().then(res => {
  //       this.list()
  //     })
  //   },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.fullPath=='/videoAdd' || to.fullPath=='/contentAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //   console.log('我是详情缓存')
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  methods: {
    deletePush(row) {//删除推荐
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          id: row.id
        }
        pushDelet(params).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async getGoodsList() {//商品列表
      const params = {
        pageIndex: 1,
        pageSize: 9999,
        brandId: this.brandId,
        // categoryId:this.categoryId
      }
      const { data } = await goodsList(params)
      if (data.code == 200) {
        // data.data.records.forEach(item=>{
        //   item.priceMoney=Number(item.priceMoney)/100
        // })
        this.goodsLists = data.data.records
        //  this.total=Number(data.data.total)
      }
    },
    changeBrand() {//改变品牌分类
      if (this.radio == 2) {//只有类型等于课程的时候才会触发课程关联
        this.getCateGorylist()
      } else if (this.radio == 3) {//积分兑换，请求商品列表
        this.getGoodsList()
        this.goodsId = ''
      }
      this.categoryId=this.coursePageId=''
      this.getcourseList()
    },
    async getCateGorylist() {//获取分类列表
      const params = {
        brandId: this.brandId ? this.brandId : 1
      }
      const { data } = await courseClassList(params)
      if (data.code == 200) {
        this.cateGoryList = data.data
      }
    },
    async getcourseList() {//获取课程包列表
      const params = {
        brandId: this.brandId,
        categoryId: this.categoryId,
      }
      const { data } = await courseList(params)
      if (data.code == 200) {
        this.coursePageList = data.data
      }
    },
    details(row) {//编辑
      this.dialogVisible=true
      this.weight=row.weight
      this.content=row.content
      this.brandId=row.brandId
      this.radio=row.jsonStr.type
      this.picUri=row.picUri
      this.isValidity=row.isValidity==1?true:false
      this.datelist=[row.startTime,row.endTime]
      this.contentId=row.id
      console.log(row)
      if(row.jsonStr.type==1){//富文本
        getRichText(row.contentUri).then(res => {
          this.msg = res.data
        })
      }else if(row.jsonStr.type==2){//课程
        this.getCateGorylist().then(res=>{
          this.categoryId=row.jsonStr.categoryId
          this.getcourseList().then(res=>{
            this.coursePageId=row.jsonStr.coursePageId
          })
        })
      }else{//积分兑换
        this.getGoodsList().then(res=>{
          this.goodsId=row.jsonStr.goodsId
        })
      }
    },
    addPush(){//添加推荐
      this.dialogVisible=true
      this.content=this.weight=this.contentId=this.picUri=this.msg=''
      this.isValidity=false
      this.radio=1
      this.datelist=[]
    },
    saveContent() {//保存推荐
      if (this.radio == 1) {//富文本
        uploadRichText(this.msg, (desUri) => {
          let jsonStr = JSON.stringify({ 'type': this.radio }).replace(/\"/g, "'");
          const params = {
            content: this.content,
            contentUri: desUri,
            brandId: this.brandId,
            startTime: this.datelist[0],
            endTime: this.datelist[1],
            isValidity: this.isValidity?1:0,
            picUri: this.picUri,
            jsonStr,
            type:1,
            id:this.contentId,
            weight:this.weight
          }
          if(this.contentId){
            pushEdit(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('更新成功')
              this.getList()
              this.dialogVisible = false
            }
          })
          }else{
            pushAdd(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('添加成功')
              this.getList()
              this.dialogVisible = false
            }
          })
          }
          
        })
      } else if (this.radio == 2) {//课程
        if (this.categoryId && this.coursePageId) {//关联课程才能正常进行
          let jsonStr = JSON.stringify({ 'type': this.radio, 'categoryId': this.categoryId, 'coursePageId': this.coursePageId }).replace(/\"/g, "'");
          const params = {
            content: this.content,
            brandId: this.brandId,
            startTime: this.datelist[0],
            endTime: this.datelist[1],
            isValidity: this.isValidity?1:0,
            picUri: this.picUri,
            jsonStr,
            type:1,
            weight:this.weight,
            id:this.contentId
          }
          console.log(params)
          if(this.contentId){
            pushEdit(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('更新成功')
              this.getList()
              this.dialogVisible = false
            }
          })
          }else{
            pushAdd(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('添加成功')
              this.getList()
              this.dialogVisible = false
            }
          })
          }
        }
      } else if (this.radio == 3) {//积分兑换
        if (this.goodsId) {//关联商品才能正常进行
          let jsonStr = JSON.stringify({ 'type': this.radio, 'goodsId': this.goodsId }).replace(/\"/g, "'");
          const params = {
            content: this.content,
            brandId: this.brandId,
            startTime: this.datelist[0],
            endTime: this.datelist[1],
            isValidity: this.isValidity?1:0,
            picUri: this.picUri,
            jsonStr,
            type:1,
            weight:this.weight,
            id:this.contentId
          }
          console.log(params)
          if(this.contentId){
            pushEdit(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('更新成功')
              this.getList()
              this.dialogVisible = false
            }
          })
          }else{
            pushAdd(params).then(res => {
            if (res.data.code == 200) {
              this.$message.success('添加成功')
              this.getList()
              this.dialogVisible = false
            }
          })
          }
        }
      }

    },
    quillchange(delta, oldDelta, source) {
      console.log(delta, oldDelta, source)
    },
    uploadimgpicUri(e) {//上传推荐封面
      if (this.$refs['fileimg'].files[0]) {
        let selectFileobj = this.$refs['fileimg'].files[0]
        uploadImg(selectFileobj, (res) => {
          this.picUri = res
        })
      }
    },
    uploadRichImgMethod(e) {//上传图片至富文本
      if (this.$refs['uploadRichImg'].files[0]) {
        let selectFileobj = this.$refs['uploadRichImg'].files[0]
        let quill = this.$refs.richAnalysis.quill
        uploadRIchImg(selectFileobj, quill)
      }
    },
    async getList() {//获取列表
      const params = {
        brandId: this.brandId,
        type: 1
      }
      const { data } = await pushList(params)
      if (data.code == 200) {
        data.data.forEach(item=>{
          item.jsonStr=JSON.parse((item.jsonStr).replace(/\'/g, '"'))
        })
        let currentTime=Date.now()
       let tableDataActive=data.data.filter(item=>{//当前推荐
          return new Date(item.endTime).getTime()>currentTime
        })
        let tableDataDie=data.data.filter(item=>{//失效推荐
          return new Date(item.endTime).getTime()<currentTime
        })
        this.tableDataActive=tableDataActive
        this.tableDataDie=tableDataDie
        this.tableData = tableDataActive
      }
    },
    changeTitle(index){//切换推荐
      this.currentIndex=index
      if(index==0){
        this.tableData = this.tableDataActive
      }else{
        this.tableData = this.tableDataDie
      }
    },
    dele(row) {//删除
      console.log(row)
      const params = {
        courseId: row.id,
        brandId: this.brandId
      }
      this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        courseDeleteAll(params).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    goback() {
      this.$router.go(-1)
    },
    async leave() {
      //获取等级列表
      const { data } = await levelList({ roleId: 3 });
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    lookvideo(row) {
      this.$router.push({ path: '/courseAdd', query: row })
    },
    lookcontent2(row) {
      this.$router.push({ path: '/courseContent', query: row })
    },
    addtext(row) {
      this.$router.push({ path: '/courseContent', query: { id: row.id, brandId: this.brandId } })
    },
    addtext2(row) {
      this.$router.push({ path: '/coursePageList3', query: { id: row.id, brandId: this.brandId } })
    },
    addtext4(row) {
      this.$router.push({ path: '/exercises', query: { id: row.id, brandId: this.brandId } })
    },
    lookcontent(row) {
      this.$router.push({ path: '/contentAdd', query: row })
    },
    async list() {//课程包列表
      const params = {
        brandId: this.brandId,
        levelId: this.leveId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        // contentType:this.radio,
        searchStr: this.input
      }
      const { data } = await courseInfoList(params)
      if (data.code == 200) {
        this.tableData = data.data.records,
          this.total = Number(data.data.total)
      }
      console.log(data)
    },
   
    details2(row) {
      this.$router.push({ path: '/courseParentAdd3', query: { row: JSON.stringify(row), brandId: this.brandId } })
    },
    lookcontent3(row) {
      this.$router.push({ path: '/courseChildAdd2', query: { id: row.id, brandId: this.brandId } })
    },
    lookcontent4(row) {
      this.$router.push({ path: '/coursePageList2', query: { id: row.id, brandId: this.brandId } })
    },
    addcontent() {
      this.$router.push({ path: '/courseParentAdd', query: { id: this.id, brandId: this.brandId } })
      //   if(this.radio=='3'){
      //     this.$router.push({path:'/contentAdd'})
      //   }else{
      //     this.$router.push({path:'/videoAdd'})
      //   }
    },
    addcontent2() {
      this.$router.push({ path: '/courseChildAdd', query: { id: this.id, brandId: this.brandId } })
      //   if(this.radio=='3'){
      //     this.$router.push({path:'/contentAdd'})
      //   }else{
      //     this.$router.push({path:'/videoAdd'})
      //   }
    },
    search() {
      console.log("我是搜索");
    },
    handleSelectionChange(val) {//选中
      console.log(val)
      if (val.length > 0) {
        this.isdisabled = false
      } else {
        this.isdisabled = true
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.list()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex = val
      this.list()
    },

    deleall() {
      this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
};
</script>

<style lang='less' scoped>
/deep/ .dialog1 .el-dialog__body {
  height: 85%;
  overflow: auto;
}

/deep/ .dialog1 .el-dialog {
  margin: 0 !important;
  float: right;
  height: 100%;
}

.titleLayout {
  display: flex;
  border-radius: 8px 8px 0 0;
  background: #C6E7E2;
  height: 34px;
  line-height: 34px;
}

.currentTitle {
  background: #fff;
  border-radius: 8px 8px 0 0;
  height: 34px;
  line-height: 34px;
}

.main {
  // min-height: 800px;
  // background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}

// .main>div:first-child {
//   font-size: 26px;
//   // font-weight: bold;
// }

// .main>div:nth-child(2) {
//   display: flex;
//   justify-content: space-between;
//   height: 34px;
//   margin: 18px 0;

//   div {
//     display: flex;

//     img {
//       width: 25px;
//       height: 25px;
//     }
//   }
// }
.changeSel {
  padding: 8px 22px;
  background: #2FAFA1;
  border-radius: 50px;
  color: #fff;
}

/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}

.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}

/deep/.el-button--primary {
  height: 34px;
  line-height: 10px;
}

/deep/.el-table::before {
  height: 0;
}

.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}

.defaultcolor {
  color: #3C4645;
}

.red {
  color: #ff1b0b;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
  color: #00A78E;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #00A78E;
  background: #00A78E
}

.selectContent {
  color: #00A78E;
}
</style>
